@import 'src/styles/variables';

.list {
  margin: 0;
  padding: 0;
  list-style: none;

  &__item {
    @include font(14);
    &:not(:last-child) {
      margin-bottom: 5px;
    }

    &_error {
      color: var(--gx-color-red);
    }
  }
}
